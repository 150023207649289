<template>
  <main id="content" role="main" class="space-top-1 bg-dark">

<!-- Hero Section -->
<div class="position-relative bg-img-hero" style="background-image: url(../../public/assets/svg/abstract-shapes-12.svg);">
  <div class="container space-top-3 position-relative z-index-2">
    <div class="row align-items-center space-top-1 lead text-white">
          <div class="col">
          </div>
          <div class="col-auto">
            <!-- Responsive Toggle Button -->
            <span class="text-dark text-body small font-weight-bold d-lg-none px-2">Account</span>
            <button type="button" class="navbar-toggler btn btn-icon btn-sm btn-dark rounded-circle d-lg-none"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="sidebarNav"
                    data-toggle="collapse"
                    data-target="#sidebarNav">
              <span class="navbar-toggler-default">
                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"/>
                </svg>
              </span>
              <span class="navbar-toggler-toggled">
                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                </svg>
              </span>
            </button>
            <!-- End Responsive Toggle Button -->
          </div>
        </div>
      </div>
    <!-- End Breadcrumb Section -->
    <!-- Content Section -->
    <div class="container space-1 space-top-lg-0 space-bottom-lg-2 mt-lg-n10">
      <div class="alert alert-primary mb-5" role="alert" v-if="showAlert">
        Succesfully saved
      </div>
      <div class="row">
        <div class="col-lg-3">
          <!-- Navbar -->
          <div class="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
            <div id="sidebarNav" class="collapse navbar-collapse navbar-vertical">
              <!-- Card -->
              <div class="card mb-5">
                <div class="card-body">

                  <h6 class="text-cap small">Settings</h6>

                  <!-- List -->
                  <ul class="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Profile' }" to="/settings/profile">
                        <i class="fas fa-id-card nav-icon"></i> Profile
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Payments' }" to="/settings/payments">
                        <i class="fas fa-credit-card nav-icon"></i> Payments
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Integrations' }" to="/settings/integrations">
                        <i class="fas fa-users nav-icon"></i> Social integrations
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Default' }" to="/settings/default">
                        <i class="fas fa-heart nav-icon"></i> Default charities
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Notifications' }" to="/settings/notifications">
                        <i class="fas fa-bell nav-icon"></i> Notifications
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" v-bind:class="{ 'active': router.currentRoute.value.name==='Skills' }" to="/settings/skills">
                        <i class="fa fa-laptop-code nav-icon"></i> Skills
                      </router-link>
                    </li>
                  </ul>
                  <!-- End List -->
                  <!-- End Nav -->
                </div>
              </div>
              <!-- End Card -->
            </div>
          </div>
          <!-- End Navbar -->
        </div>
        <router-view @saved="alert"></router-view>
      </div>
      <!-- End Row -->
  </div>

</div>
<!-- End Hero Section -->

  </main>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
const store = useStore()
const router = useRouter()
if (store.state.userProfile.registrationCompleted==false){ router.push('/register') }

const showAlert = ref(false)
const alert = () => {
  showAlert.value = true
  setTimeout(() => showAlert.value = false, 2000)
}
</script>

<style lang="scss" scoped>
</style>
